import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ContactForm from "../components/contact-form"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"

const IndexPage = () => (<div>
  <Layout title="About" hero={ <Carousel images={["/about-image.jpg"]}/>}>
    <SEO title="About" />
    <div style={{ }}>
      <ContactForm><div className={"titles inline"}><h3>Contact Frances</h3></div></ContactForm> 
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div style={{padding:"40px"}}>

         <p> Frances Keevil has been an active member of Sydney’s art scene for 30 years, initially as a picture framer and then as a gallerist, art consultant, art dealer and exhibitor.

         </p><p>  Frances opened the Frances Keevil Gallery in Double Bay in 2005. Over the past two decades Frances has exhibited over 400 artists in solo and mixed exhibitions. The gallery has also participated in a number of prominent Australian art fairs. The longevity and diversity of Frances’ career has afforded the gallery a unique vantage point to witness the changes and the evolution – both institutionally and aesthetically – in Sydney’s artistic community. This diversity and enthralment is reflected in the curation of the gallery’s ongoing exhibition programme.

        </p><p> Following the easing of the COVID-19 pandemic, Frances Keevil has returned to her original and enduring passion, in developing and engaging with emerging talent within the arts community: fusing an online gallery that offers continuity to the showcasing of works, punctuated by exhibitions held at Studio W in Woolloomooloo.

        </p><p>  Since June 2021, the gallery (now known as Frances Keevil) has exhibited nine solo and four mixed exhibitions. Many of the artists represented by Frances Keevil have been included in major art prizes, have exhibited in regional art galleries, and have been included in major public art collections including, as an example, those of the Mitchell Library, Art Bank and the Sydney Town Hall.
        </p>
      </div>, span:"12"}] } />

    </div>

  </Layout>

</div>
)

export default IndexPage
